<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{  'ad-type-property': adtype === 'property',
    'ad-type-marketplace': adtype === 'marketplace',
  }">
    <div class="cart-item-container row">

      <div class="">
        <div class="d-flex align-items-start">
          <div class="cart-item-title">
            {{ title }}
          </div>
          <div class="icon-container ms-auto">
            <xIcon @click="removeItemFromCart(id)"/>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="plus-minus-input me-auto">
            <span> {{ quantity }}</span>
            <span class="ps-2">{{ $t('quantity') }}</span>
          </div>
          <div class="price-container">
            <div class="discounted-price">
              <div class="price-amount">
                {{ parseFloat(discountedprice).toFixed(2) }} {{ $t('currency') }}
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>


  </div>
</template>
