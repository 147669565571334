<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="service-container text-center">
    <div class="service-title"> {{ $t(serviceTitle) }}</div>
    <div> {{ $t('prices-from') }}:</div>
    <div class="price-amount"> {{ servicePrice }}{{ $t('price-per-square-meter') }}&sup2;</div>
    <div class="service-button" @click="goToNextStage">
      <Button :theme="'solid-orange'" :size="'medium'" :text="$t(buttonText)"/>
    </div>
    <div class="service-item" v-for="(item, index) in items">
      <div class=""> {{ $t(item.serviceItem) }}</div>
    </div>
  </div>
</template>
