<template>
  <div class="cleaning-stage-2-wrapper text-center">
    <div class="stage-2-title"> {{ $t('cleaning-stage-2-title') }}</div>
    <div class="stage-2-subtitle">{{ $t('cleaning-stage-2-subtitle') }}</div>
    <div class="stage-2-one-time-wrapper" v-if="selectedService === 'one-time'">
      <div v-for="(item, index) in getSelectedServices()" :key="index"
           class="d-flex justify-content-center align-items-center selected-service-wrapper">
        <div class="me-auto">{{ item.selectedServiceTitle }}</div>
        <div v-if="item.inputType === 'plus-minus'" class="d-flex align-items-center">
          <PlusMinusInput/>
          <div class=" input-info"> {{ $t('piece') }}</div>
        </div>
        <div v-if="item.inputType === 'number'" class="d-flex align-items-center">
          <Input :size="'small'" :theme="'border-rectangle'"/>
          <div class=" input-info"> {{ $t('square-meter') }}</div>
        </div>
      </div>
    </div>
    <div class="stage-2-deep-cleaning-wrapper row" v-if="selectedService === 'deep'">
      <div class="col-md-4 input-item">
        <Input :label="$t('input-name')" :inputtype="'text'"/>
      </div>
      <div class="col-md-4 input-item">
        <Input :label="$t('input-phone')" :inputtype="'text'"/>
      </div>
      <div class="col-md-4 input-item">
        <Input :label="'Email'" :inputtype="'email'"/>
      </div>
      <div v-bind:class="{'row input-item text-area w-100': true,}">
        <TextArea :label="$t('additional-information')"/>
      </div>
    </div>

    <div class="stage-2-subscription-cleaning-wrapper" v-if="selectedService === 'subscription'">
      <div class="stage-2-subscription-title text-center font-bold"> {{ $t('service') }}: {{
          selectedSubscriptionService
        }}
      </div>
      <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
        <div class="me-auto font-bold">{{ $t('enter-room-size') }}:</div>
        <div class="d-flex align-items-center">
          <Input :size="'small'" :theme="'border-rectangle'"/>
          <div class=" input-info"> {{ $t('square-meter') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlusMinusInput from "@/components/Common/Inputs/PlusMinusInput/PlusMinusInput";
import Input from "@/components/Common/Inputs/Input/Input";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";

export default {
  name: "SelectCleaningStage2",
  props: {
    selectedServiceTitle: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: ''
    },
    selectedService: {
      type: String,
      default: ''
    },
  },
  components: {
    PlusMinusInput,
    Input,
    TextArea
  },
  data() {
    return {
      selectedSubscriptionService: 'Стандарт',
    }
  },
  methods: {
    getSelectedServices() {
      return [
        {selectedServiceTitle: 'Service 1', inputType: 'plus-minus'},
        {selectedServiceTitle: 'Service 2', inputType: 'number'},
      ];
    },

  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.font-bold {
  font-family: $font-bold;
}

.cleaning-stage-2-wrapper {
  padding: $spacing-3;
}

.stage-2-title {
  @include subtitle-3;
  padding-bottom: $spacing-1;
}

.stage-2-subtitle {
  margin-bottom: $spacing-8;
  @include paragraph-1;
}

.selected-service-wrapper {
  padding: $spacing-3 20px $spacing-3 0;
  border-bottom: 1px solid $color-grey-200;
}

.stage-2-one-time-wrapper, {
  border-top: 1px solid $color-grey-200;
}

.stage-2-subscription-title {
  padding-bottom: $spacing-3;
  border-bottom: 1px solid $color-grey-200;
}

.input-info {
  font-size: 11px;
  width: 10px;
  padding-left: 5px;
}

.input-container {
  text-align: center;
}

.input-item {
  margin: $spacing-1 0;
}

.text-area, .file-input {
  height: 15rem;
  padding: 5px;
}

</style>
