<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4213_23964)">
      <path d="M16.5014 33C11.4662 33 6.43231 33 1.39712 33C0.112752 33 -0.243298 32.5163 0.154183 31.3148C1.99399 25.7572 3.8338 20.1984 5.67619 14.6408C5.99858 13.6669 6.19538 13.5233 7.20268 13.5233C13.3992 13.5233 19.5958 13.5233 25.7924 13.5233C26.8023 13.5233 27.0003 13.6669 27.3214 14.6356C29.1703 20.2139 31.0192 25.7908 32.8642 31.3704C33.2332 32.4878 32.8577 32.9987 31.6691 32.9987C26.6132 33 21.5573 32.9987 16.5014 32.9987V33ZM2.2866 31.038H15.4928V23.3477C15.4125 23.3089 15.375 23.2753 15.3374 23.2753C11.9634 23.2675 8.58803 23.2571 5.21398 23.2701C5.0599 23.2701 4.81779 23.4511 4.76729 23.5986C3.93349 26.0508 3.12429 28.5108 2.2866 31.038ZM30.6735 31.0393C30.6515 30.9306 30.6437 30.8466 30.6178 30.769C29.8371 28.4086 29.0408 26.0547 28.2834 23.6865C28.1488 23.2636 27.9028 23.252 27.561 23.252C24.4005 23.2571 21.2414 23.2546 18.081 23.2571C17.8945 23.2571 17.7094 23.2843 17.5139 23.2998V31.038H30.6722L30.6735 31.0393ZM15.5032 15.4621C12.8865 15.4621 10.3515 15.4582 7.81767 15.4724C7.66619 15.4724 7.4189 15.594 7.37746 15.7143C6.74823 17.5509 6.14618 19.3952 5.52212 21.2835H15.5032V15.4621ZM17.4945 21.2938H27.4794C26.8515 19.3913 26.252 17.5444 25.6137 15.7104C25.5671 15.5785 25.2421 15.475 25.0453 15.4724C23.0903 15.4556 21.1339 15.4621 19.1776 15.4621C18.6261 15.4621 18.0745 15.4621 17.4945 15.4621V21.2938Z" fill="black"/>
      <path d="M27.1314 4.79318C27.1457 5.31699 26.7974 5.70111 26.2329 5.78647C24.2753 6.08007 23.6046 6.73321 23.277 8.66807C23.1683 9.30828 22.8096 9.68465 22.2904 9.64844C21.6729 9.60446 21.4204 9.20999 21.3323 8.62927C21.0475 6.76296 20.3017 6.04903 18.4127 5.78906C17.8392 5.71017 17.4857 5.33897 17.4883 4.81775C17.4909 4.27454 17.8547 3.90335 18.4865 3.8542C19.8538 3.74814 20.7756 3.06913 21.2106 1.76543C21.2974 1.50417 21.3155 1.21834 21.3492 0.942853C21.4165 0.373776 21.7208 0.00646226 22.2982 -4.52909e-06C22.8757 -0.00647131 23.1851 0.347908 23.2705 0.918279C23.3275 1.29853 23.3599 1.69947 23.5088 2.04609C23.9839 3.14544 24.8449 3.74426 26.0464 3.84644C26.7482 3.90593 27.1159 4.23445 27.1301 4.79318H27.1314ZM22.2607 5.63774C22.5947 5.35449 22.9054 5.09323 23.1721 4.8669C22.8679 4.56555 22.5688 4.27066 22.3124 4.01716C22.0315 4.27842 21.7259 4.56166 21.4709 4.80094C21.7505 5.09711 22.0276 5.39071 22.2607 5.63774Z" fill="black"/>
      <path d="M10.6863 1.91417C11.2132 1.98013 11.5447 2.24656 11.6392 2.79494C11.7117 3.21528 11.7492 3.6602 11.9189 4.04303C12.3811 5.09065 13.2214 5.66231 14.3672 5.77613C15.1285 5.85114 15.4975 6.15896 15.5104 6.73321C15.5234 7.33204 15.1453 7.65796 14.3478 7.73686C12.9184 7.87654 11.8878 8.87113 11.6988 10.2887C11.6703 10.5008 11.7026 10.7827 11.5796 10.9095C11.3181 11.1785 10.9802 11.5264 10.6604 11.5393C10.3768 11.551 10.0713 11.1604 9.78903 10.9302C9.73724 10.8888 9.75149 10.7672 9.73465 10.6831C9.3307 8.58013 8.83352 8.09382 6.71924 7.71358C6.1871 7.61787 5.87507 7.301 5.86731 6.74485C5.85954 6.20294 6.23501 5.85244 6.86683 5.7826C8.31045 5.62481 9.29574 4.86173 9.61036 3.63304C9.68934 3.32263 9.69711 2.99541 9.76443 2.67983C9.86801 2.19353 10.2098 1.97495 10.6863 1.91287V1.91417ZM10.6034 7.58424C10.9491 7.26866 11.2547 6.9893 11.4942 6.77072C11.2262 6.48618 10.9517 6.19388 10.6889 5.91452C10.4027 6.1913 10.1024 6.4823 9.846 6.73063C10.1088 7.02681 10.3768 7.32945 10.6021 7.58424H10.6034Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_4213_23964">
        <rect width="33" height="33" fill="white"/>
      </clipPath>
    </defs>
  </svg>


</template>
