<template>
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4213_23999)">
      <path d="M31 31H0V14.686H6.56576C6.60592 14.6508 6.64607 14.6167 6.68623 14.5815C4.22102 12.4226 3.72823 9.64042 4.02877 6.55087H7.29097V4.75157H9.11979V6.51442H14.6014C14.6014 6.01873 14.5978 5.53884 14.6014 5.06016C14.6087 4.19149 14.5807 3.32039 14.6416 2.45658C14.7413 1.06549 15.9362 0 17.327 0C18.7178 0 19.9042 1.05455 20.0112 2.45415C20.0684 3.19161 20.021 3.93757 20.021 4.70054H18.2469C18.2469 4.13682 18.27 3.58281 18.2372 3.03002C18.2201 2.73844 18.1921 2.36546 18.012 2.18808C17.8015 1.98154 17.3745 1.80295 17.125 1.8807C16.8452 1.96818 16.4789 2.34602 16.4643 2.61452C16.3962 3.89869 16.4339 5.18894 16.4339 6.50713H21.8729V4.754H23.6835V6.51442H26.9627C27.2219 9.59547 26.9846 12.0217 24.3369 14.618H30.9976V30.9988L31 31ZM1.83978 16.5023V21.882H29.1821V16.5023H1.83978ZM29.1919 23.7991H1.82396V29.174H29.1919V23.7991ZM5.84665 8.38905C5.51203 11.5709 8.25344 14.5754 11.5753 14.641C14.1621 14.6921 16.7515 14.6872 19.3371 14.6313C20.0854 14.6155 20.8691 14.4357 21.5675 14.1575C23.9013 13.2293 25.4831 10.6318 25.1461 8.38905H5.84665Z" fill="black"/>
      <path d="M18.1454 18.3429V20.0681H12.8828V18.3429H18.1454Z" fill="black"/>
      <path d="M18.1377 25.6069V27.3528H12.8945V25.6069H18.1377Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_4213_23999">
        <rect width="31" height="31" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
