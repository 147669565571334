<template>
  <div class="d-flex justify-content-center w-100">
    <div class="col-md-7">
      <div class="row">
        <div class="col-md-4 col-12"
             @click="showService('one-time')"
             :class="{ 'service-button-active': selectedService === 'one-time' }">
          <div class="service-wrapper text-center d-flex  align-items-center">
            <div class="service-icon">
              <SingleCleaningIcon/>
            </div>
            <div class="service-title"> {{ $t('one-time') }}</div>
          </div>
        </div>
        <div class="col-md-4 col-12"
             @click="showService('deep')"
             :class="{ 'service-button-active': selectedService === 'deep' }"
        >
          <div class="service-wrapper d-flex  align-items-center text-center">
            <div class="service-icon">
              <DeepCleaningIcon/>
            </div>
            <div class="service-title "> {{ $t('deep-cleaning') }}</div>
          </div>
        </div>
        <div class="col-md-4 col-12 "
             @click="showService('subscription')"
             :class="{ 'service-button-active': selectedService === 'subscription' }"
        >
          <div class="service-wrapper text-center d-flex  align-items-center">
            <div class="service-icon">
              <SubscriptionCleaningIcon/>
            </div>
            <div class="service-title"> {{ $t('subscription-cleaning') }}</div>
          </div>
        </div>
      </div>
      <div class="service-subtitle text-center"> {{ $t('select-cleaning-subtitle-stage1') }}</div>
    </div>
  </div>

  <div class="service-one-time-cleaning" v-show="selectedService === 'one-time'">
    <div class="d-flex justify-content-center w-100">
      <div class="col-md-7">
        <div v-for="(item, index) in dropdownItems" :key="index">
          <ServiceItemDropdown
              :icon="item.icon"
              :service-title="$t(item.title)"
              :items="item.items"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="service-deep-cleaning" v-show="selectedService === 'deep'">
    <div class="row">
      <div class="col-md-4 col-12" v-for="(item, index) in deepCleaningServices" :key="index">
        <DeepCleaningServiceSelectBox :go-to-stage="goToStage"
                                      :service-title="item.cleaningTitle"
                                      :service-price="item.cleaningPrice"
                                      :button-text="item.buttonText"
                                      :items="item.items"
        />
      </div>
    </div>
  </div>
  <div class="service-subscription-cleaning" v-show="selectedService === 'subscription'">
    <div class="d-flex justify-content-center w-100">
      <div class="col-md-7 ">
        <div class="d-flex align-items-center subscription-service-wrapper"
             v-for="(title, index) in subscriptionServices" :key="index">
          <TickIcon/>
          <div class="ms-2">{{ $t(title) }}</div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12" v-for="(item, index) in subscriptionServicesSelect" :key="index">
            <SubscriptionCleaningServiceSelectBox
                :go-to-stage="goToStage"
                :service-title="item.cleaningTitle"
                :service-price="item.cleaningPrice"
                :button-text="item.buttonText"

            />
          </div>
        </div>

      </div>
    </div>
  </div>


</template>

<script>
import SingleCleaningIcon from "@/components/Common/Icons/SingleCleaningIcon"
import DeepCleaningIcon from "@/components/Common/Icons/DeepCleaningIcon"
import SubscriptionCleaningIcon from "@/components/Common/Icons/SubscriptionCleaningIcon"
import GalleryMain from "@/components/Components/GalleryMain";
import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import ServiceItemDropdown from "@/views/SelectCleaningServicePage/ServiceItemDropdown";
import DeepCleaningServiceSelectBox
  from "@/components/Components/DeepCleaningServiceSelectBox/DeepCleaningServiceSelectBox";
import TickIcon from "@/components/Common/Icons/TickIcon/";
import SubscriptionCleaningServiceSelectBox
  from "@/components/Components/SubscriptionCleaningServiceSelectBox/SubscriptionCleaningServiceSelectBox";


export default {
  name: "SelectCleaningStage1",
  components: {
    GalleryMain,
    Button,
    InputField,
    TextArea,
    SingleCleaningIcon,
    SubscriptionCleaningIcon,
    DeepCleaningIcon,
    ServiceItemDropdown,
    DeepCleaningServiceSelectBox,
    SubscriptionCleaningServiceSelectBox,
    TickIcon
  },
  props: {
    goToStage: Function, // Receive goToStage method as prop
  },
  data() {
    return {
      selectedService: 'one-time',
      dropdownItems: [
        {
          icon: 'hard-floor',
          title: 'hard-floor-cleaning',
          items: [
            {serviceSubtitle: 'hard-floor-cleaning-option-1'},
            {serviceSubtitle: 'hard-floor-cleaning-option-2'},
            {serviceSubtitle: 'hard-floor-cleaning-option-3'},
            {serviceSubtitle: 'hard-floor-cleaning-option-4'},
            {serviceSubtitle: 'hard-floor-cleaning-option-5'},
          ]
        },
        {
          icon: 'soft-floor',
          title: 'soft-floor-cleaning',
          items: [
            {serviceSubtitle: 'soft-floor-cleaning-option-1'},
            {serviceSubtitle: 'soft-floor-cleaning-option-2'},
            {serviceSubtitle: 'soft-floor-cleaning-option-3'},
            {serviceSubtitle: 'soft-floor-cleaning-option-4'},
          ]
        },
        {
          icon: 'soft-furniture',
          title: 'soft-furniture-cleaning',
          items: [
            {serviceSubtitle: 'soft-furniture-cleaning-option-1'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-2'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-3'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-4'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-5'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-6'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-7'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-8'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-9'},
            {serviceSubtitle: 'soft-furniture-cleaning-option-10'},
          ]
        },
        {
          icon: 'leather-furniture',
          title: 'leather-furniture-cleaning',
          items: [
            {serviceSubtitle: 'leather-furniture-cleaning-option-1'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-2'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-3'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-4'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-5'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-6'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-7'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-8'},
            {serviceSubtitle: 'leather-furniture-cleaning-option-9'},
          ]
        },
        {
          icon: 'auto-cleaning',
          title: 'auto-cleaning',
          items: [
            {serviceSubtitle: 'auto-cleaning-option-1'},
            {serviceSubtitle: 'auto-cleaning-option-2'},
            {serviceSubtitle: 'auto-cleaning-option-3'},
            {serviceSubtitle: 'auto-cleaning-option-4'},
            {serviceSubtitle: 'auto-cleaning-option-5'},
            {serviceSubtitle: 'auto-cleaning-option-6'},
          ]
        },
        {
          icon: 'sanitary-room',
          title: 'sanitary-room-cleaning',
          items: [
            {serviceSubtitle: 'sanitary-room-cleaning-option-1'},
            {serviceSubtitle: 'sanitary-room-cleaning-option-2'},
            {serviceSubtitle: 'sanitary-room-cleaning-option-3'},
          ]
        },
      ],
      deepCleaningServices: [
        {
          cleaningTitle: 'standard-cleaning',
          cleaningPrice: 20,
          buttonText: 'choose-service',
          items: [
            {serviceItem: 'deep-cleaning-option-1'},
            {serviceItem: 'deep-cleaning-option-2'},
            {serviceItem: 'deep-cleaning-option-3'},
            {serviceItem: 'deep-cleaning-option-4'},
            {serviceItem: 'deep-cleaning-option-5'},
            {serviceItem: 'deep-cleaning-option-6'},
            {serviceItem: 'deep-cleaning-option-7'},
            {serviceItem: 'deep-cleaning-option-8'},
          ]
        },
        {
          cleaningTitle: 'pro-cleaning',
          cleaningPrice: 25,
          buttonText: 'choose-service',
          items: [
            {serviceItem: 'deep-cleaning-option-1'},
            {serviceItem: 'deep-cleaning-option-2'},
            {serviceItem: 'deep-cleaning-option-3'},
            {serviceItem: 'deep-cleaning-option-4'},
            {serviceItem: 'deep-cleaning-option-5'},
            {serviceItem: 'deep-cleaning-option-6'},
            {serviceItem: 'deep-cleaning-option-7'},
            {serviceItem: 'deep-cleaning-option-8'},
            {serviceItem: 'deep-cleaning-option-9'},
            {serviceItem: 'deep-cleaning-option-10'},
            {serviceItem: 'deep-cleaning-option-11'},
          ]
        },
        {
          cleaningTitle: 'pro-plus-cleaning',
          cleaningPrice: 30,
          buttonText: 'choose-service',
          items: [
            {serviceItem: 'deep-cleaning-option-1'},
            {serviceItem: 'deep-cleaning-option-2'},
            {serviceItem: 'deep-cleaning-option-3'},
            {serviceItem: 'deep-cleaning-option-4'},
            {serviceItem: 'deep-cleaning-option-5'},
            {serviceItem: 'deep-cleaning-option-6'},
            {serviceItem: 'deep-cleaning-option-7'},
            {serviceItem: 'deep-cleaning-option-8'},
            {serviceItem: 'deep-cleaning-option-9'},
            {serviceItem: 'deep-cleaning-option-10'},
            {serviceItem: 'deep-cleaning-option-11'},
            {serviceItem: 'deep-cleaning-option-12'},
            {serviceItem: 'deep-cleaning-option-13'},
            {serviceItem: 'deep-cleaning-option-14'},
          ]
        },
      ],
      subscriptionServicesSelect: [
        {
          cleaningTitle: 'once-a-week',
          cleaningPrice: 5.40,
          buttonText: 'choose-service',

        },
        {
          cleaningTitle: 'twice-a-week',
          cleaningPrice: 8.40,
          buttonText: 'choose-service',
        },
        {
          cleaningTitle: 'three-times-a-week',
          cleaningPrice: 13.20,
          buttonText: 'choose-service',
        },
        {
          cleaningTitle: 'monday-to-friday',
          cleaningPrice: 18,
          buttonText: 'choose-service',
        },
      ],
      subscriptionServices: ['subscription-service-1', 'subscription-service-2', 'subscription-service-3', 'subscription-service-4', 'subscription-service-5', 'subscription-service-6', 'subscription-service-7', 'subscription-service-8', 'subscription-service-9', 'subscription-service-10', 'subscription-service-11',],
    }
  },
  methods: {
    showService(serviceType) {

      this.selectedService = serviceType;
      this.$emit('service-selected', serviceType);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.service-wrapper {
  margin-top: $spacing-5;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 59px 0px #00000021;
  border-radius: 20px;
  color: $color-orange-main;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  padding: $spacing-1 $spacing-3;

  &:hover {
    background: $color-orange-main;
    color: $color-text-primary-dark;
    transition: 0.3s ease-in-out;
  }

  .service-title {
    @include subtitle-2;
    margin-left: $spacing-1;
  }


}

.service-subtitle {
  @include subtitle-3;
  margin: $spacing-5;
}

.service-button-active {
  .service-wrapper {
    background: $color-orange-main;
    color: $color-text-primary-dark;
    transition: 0.3s ease-in-out;
  }
}

.subscription-service-wrapper {
  border-bottom: 1px solid $color-grey-200;
  padding: $spacing-1 0;
  @include body-3;
}
</style>
