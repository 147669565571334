import Button from "@/components/Common/Buttons/Button/Button";

export default {
    name: 'DeepCleaningServiceSelectBox',
    props: {
        serviceTitle: {
            type: String,
            default: ''
        },
        serviceItem: {
            type: String,
            default: ''
        },
        servicePrice: {
            type: Number,
            default: ''
        },
        buttonText: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            required: true
        },
        goToStage: Function,

    },
    components: {
        Button
    },
    methods: {
        goToNextStage() {
            // Call the goToStage method received as prop with the desired stage
            this.goToStage(1);
        },
    },
}
