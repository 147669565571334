import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import PlusMinusInput from "@/components/Common/Inputs/PlusMinusInput/PlusMinusInput";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import xIcon from "@/components/Common/Icons/xIcon/";

export default {
    name: 'CleaningCartItem',
    props: {
        adtype: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        regularprice: {
            type: String,
            default: ''
        },
        discountedprice: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },

    },
    components: {
        Dropdown,
        InputField,
        Button,
        PlusMinusInput,
        HeartIcon,
        xIcon
    },
    mounted() {
    },
    watch: {
        quantity: async function (newVal, oldVal) {
            this.$emit('update:modelValue', newVal);
            await this.$store.dispatch('cart/changeProductFromCart', {
                productId: this.$props.id,
                quantity: newVal,
            });
        }
    },
    data() {
        return {
            quantity: 1,
        }
    },
    methods: {
        async removeItemFromCart(id) {
            await this.$store.dispatch('cart/removeProductFromCart', id);
        },
        async changeQuantity(id) {
            await this.$store.dispatch('cart/removeProductFromCart', id);
        }
    }
}