<template>
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_4213_23973)">
    <path d="M13.2761 5.75119H31.0003V31.0001H30.3753C22.5195 31.0001 14.6625 31.0026 6.80667 31.0001C2.94444 30.9977 0.0149287 28.0888 0.00763226 24.2317C-0.00452842 18.4637 0.000335854 12.6969 0.00520012 6.93005C0.00641619 3.24535 2.48963 0.420217 6.05271 0.0377854C9.40419 -0.322793 12.4273 1.97301 13.2129 5.47559C13.2263 5.53387 13.2397 5.59214 13.2761 5.75119ZM13.3005 11.2193V25.515H25.5378V11.2193H13.3005ZM1.82565 19.6996C5.07134 17.0335 8.25865 17.0347 11.4812 19.6802C11.491 19.5369 11.5043 19.4422 11.5043 19.3475C11.5043 15.0959 11.5092 10.8442 11.497 6.59254C11.497 6.19554 11.4277 5.78761 11.3231 5.40275C10.6944 3.07902 8.4362 1.55901 6.08554 1.85889C3.6534 2.16847 1.83781 4.19718 1.83052 6.66174C1.81714 10.8527 1.82687 15.0437 1.82687 19.2358C1.82687 19.3682 1.82687 19.5017 1.82687 19.702L1.82565 19.6996ZM29.1689 29.1548V7.60264H13.3625V9.39825H27.3461V27.3701H26.5751C22.0914 27.3701 17.6078 27.3677 13.1241 27.3786C12.9101 27.3786 12.617 27.4381 12.4979 27.5813C12.0953 28.0694 11.7536 28.6072 11.3681 29.1548H29.1677H29.1689ZM6.64736 29.1803C9.32514 29.1851 11.5092 27.0083 11.5031 24.341C11.497 21.7077 9.31663 19.5284 6.67898 19.5211C4.0389 19.5126 1.84389 21.6785 1.82687 24.3094C1.80984 26.9877 3.97445 29.1754 6.64736 29.1803Z" fill="black"/>
    <path d="M6.65752 27.4889C4.92706 27.4841 3.50061 26.0417 3.52371 24.3166C3.5456 22.6156 4.97205 21.2122 6.67333 21.217C8.39771 21.2231 9.83511 22.6776 9.80835 24.393C9.7816 26.1012 8.36366 27.4938 6.65752 27.4889ZM6.67698 23.0321C5.96923 23.0248 5.35998 23.6185 5.34538 24.3287C5.33079 25.0426 5.90842 25.6496 6.62347 25.6702C7.36527 25.6921 7.99155 25.0875 7.98912 24.3518C7.98668 23.6452 7.38473 23.0394 6.67698 23.0321Z" fill="black"/>
  </g>
  <defs>
    <clipPath id="clip0_4213_23973">
      <rect width="31" height="31" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
