import Button from "@/components/Common/Buttons/Button/Button";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import StepProgressBar from "@/components/Components/StepProgressBar/StepProgressBar";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import FileService from '@/services/FileService';
import xIcon from "@/components/Common/Icons/xIcon/";
import SelectCleaningStage1 from "@/views/SelectCleaningServicePage/SelectCleaningStage1";
import SelectCleaningStage2 from "@/views/SelectCleaningServicePage/SelectCleaningStage2";
import SelectCleaningStage3 from "@/views/SelectCleaningServicePage/SelectCleaningStage3";
import Footer from "@/components/Footer/Footer";
import CleaningLocationHoursInfo from "@/components/Components/CleaningLocationHoursInfo/CleaningLocationHoursInfo";

export default {
    name: 'SelectCleaningServicePage',
    components: {
        Button,
        Dropdown,
        InputField,
        StepProgressBar,
        TextArea,
        FileInput,
        CreditWallet,
        PremiumAdSelection,
        SelectCleaningStage1,
        SelectCleaningStage2,
        SelectCleaningStage3,
        CleaningLocationHoursInfo,
        xIcon,
        Footer
    },
    data() {
        return {
            selectedService: 'one-time',
            stage: 0,
            form: {
                title: null,
                name: null,
                phone: null,
                email: null,
                price: null,
                description: null,
                districtId: null,
                images: [],
            },
            formErrors: [],
            cities: null,
            //Holds the cities mapped as {name: id}
            citiesMapped: null,
            //Holds the selected city id, so it can be used to get the districts
            propertyLocationCity: null,
            //Holds the districts for the selected city
            propertyLocationDistricts: {},
            isOfferEditing: false,
            selectedPlan: 'regular'
        };
    },
    watch: {
        propertyLocationCity: async function (cityId, oldVal) {
            this.propertyLocationDistricts = await this.$store.dispatch("location/getDistrictsMapped", cityId);
        }
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
        isRegularPlanSelected() {
            return this.selectedPlan === 'regular';
        },
        isPremium7PlanSelected() {
            return this.selectedPlan === 'premium-7';
        },
        isPremium30PlanSelected() {
            return this.selectedPlan === 'premium-30';
        },
    },
    async mounted() {
        const id = this.$route.params.id;
        if (id) {
            this.form = await this.$store.dispatch("roommate/getSingleRoommatePost", {id: id});
            this.form.districtId = this.form.district.id;
            this.propertyLocationCity = this.form.city.id;
        }

        this.isOfferEditing = this.$route.name === 'EditRoommateOffer';
        this.cities = await this.$store.dispatch("location/getCities");
        this.citiesMapped = await this.$store.dispatch("location/getCitiesMapped");
    },
    methods: {
        handleServiceSelected(serviceType) {
            this.selectedService = serviceType;
        },
        async uploadPhoto(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file, file.name);
            const image = await FileService.uploadFile(formData);
            this.form.images.push(image);
        },
        goToStage(goToStage) {
            this.stage = goToStage;
        },

        removeImage(index) {
            this.form.images.splice(index, 1);
        },
        async publish() {
            try {
                let post = null;
                const payload = {plan: this.selectedPlan, ...this.form};
                if (this.isOfferEditing) {
                    this.form.id = this.$route.params.id;
                    post = await this.$store.dispatch('roommate/updateRoommatePost', payload);
                } else {
                    post = await this.$store.dispatch('roommate/publishRoommatePost', payload);
                }
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: 'Вашата обява беше публикувана успешно!.'
                });
                this.form = {
                    name: null,
                    phone: null,
                    email: null,
                    price: null,
                    description: null,
                    districtId: null,
                    images: [],
                };
                this.$router.push({name: 'SingleRoommateOffer', params: {id: post.id}});
            } catch (err) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error', message: err.message
                });
            }
        },
        selectPlan(plan) {
            let hasEnoughCredits = false;
            const userCredits = this.$store.state.user.info.credits;
            switch (plan) {
                case 'regular':
                    hasEnoughCredits = userCredits >= 1;
                    break;
                case 'premium-7':
                    hasEnoughCredits = userCredits >= 10;
                    break;
                case 'premium-30':
                    hasEnoughCredits = userCredits >= 20;
                    break;
            }

            if (hasEnoughCredits) {
                this.selectedPlan = plan;
            } else {
                this.$store.dispatch('utils/openNotificationModal', {
                    message: "Нямате достатъчно кредити за този план",
                    type: 'error'
                });
            }
        },
    },
};
