<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'create-roommate-offer': true, 'edit-roommate-offer': isOfferEditing}" class="container">
    <div class="add-roommate-title text-center mb-5" v-if="isOfferEditing === false">
      {{ $t('select-cleaning-service-title') }}
    </div>
    <div class="row">
      <StepProgressBar :stage="stage" :step1="$t('select-service')" :step2="$t('data-entry')"
                       :step3="$t('send-request')"/>
    </div>
    <div v-if="stage === 0" class="input-container row justify-content-center stage-1  ">
      <div class="">
        <SelectCleaningStage1 @service-selected="handleServiceSelected" :go-to-stage="goToStage"/>
        <div class="button d-flex justify-content-center" v-if="selectedService === 'one-time'">
          <div class="col-md-7">
            <div class="d-flex " v-bind:class="{'row mt-4': true,  '': !isOfferEditing}">
              <div class="col-md-4  add-roommate-buttons ms-auto" @click="goToStage(1)">
                <Button :text="$t('continue')" :size="'medium'" :theme="'solid-orange'"/>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5 ">
          <div class="col-md-7 text-left">
            <CleaningLocationHoursInfo/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="stage === 1" class="input-container row justify-content-center stage-2  ">
      <div class="col-md-7">
        <SelectCleaningStage2 :selected-service="selectedService"/>
      </div>
      <div v-bind:class="{'row mt-4': true,  'col-md-7': !isOfferEditing}">
        <div class="col-md-4 me-auto add-roommate-buttons" @click="goToStage(0)">
          <Button :text="$t('back')" :size="'medium'" :theme="'outline-grey'"/>
        </div>
        <div class="col-md-4 add-roommate-buttons" @click="goToStage(2)">
          <Button :text="$t('continue')" :size="'medium'" :theme="'solid-orange'"/>
        </div>
      </div>
    </div>
    <div v-if="stage === 2" class="row justify-content-center stage-3  ">
      <div class="col-md-7">
        <SelectCleaningStage3 :selected-service="selectedService"/>
      </div>
      <div v-bind:class="{'row col mt-4': true,  'col-md-7': !isOfferEditing}">
        <div class="col-md-4 me-auto add-roommate-buttons" @click="goToStage(1)">
          <Button :text="$t('back')" :size="'medium'" :theme="'outline-grey'"/>
        </div>
        <div class="col-md-4 add-roommate-buttons">
          <Button v-if="selectedService === 'one-time' || selectedService === 'subscription' " :text="$t('payment')"
                  :size="'medium'" :theme="'solid-orange'"
                  @click="publish()"/>
          <Button v-if="selectedService === 'deep'" :text="$t('request-service')" :size="'medium'"
                  :theme="'solid-orange'" @click="publish()"/>
        </div>
      </div>
    </div>
  </div>
  <div>
    <Footer/>
  </div>
</template>
